import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "food-list"
};
const _hoisted_2 = {
  key: 1
};
import internal from 'stream';
import { reactive } from 'vue';
import ListItemVue from '../../../components/ListItem.vue';
export default {
  __name: 'foodlist',
  props: {
    index: {
      type: internal,
      require: true
    },
    foodData: {
      type: Object,
      require: true
    }
  },
  setup(__props) {
    const props = __props;
    const data = reactive({
      activeIndex: 1,
      items: [{
        text: '分组 1'
      }, {
        text: '分组 2'
      }],
      subItem: []
    });
    console.log(props.index);
    const initData = () => {
      const newArray = [];
      props.foodData.items.forEach((item, index) => {
        newArray.push({
          text: item.text
        });
        if (data.activeIndex === index) data.subItem = item.children;
      });
      return newArray;
    };
    let items;
    if (props.index === 0) {
      items = initData();
    }
    const navClick = i => {
      // console.log(i)
      data.activeIndex = i;
      items = initData();
    };
    const handleAdd = id => {
      data.subItem.forEach((item, index) => {
        if (item.id === id) {
          item.add = false;
          item.num += 1;
        }
      });
    };
    const handleChange = (value, detail) => {
      // console.log(value, detail)
      data.subItem.forEach(item => {
        if (item.id === detail.name) {
          item.num = value;
        }
      });
    };
    // const items = initData()
    // initData()
    // const items = [{ text: '分组 1' }, { text: '分组 2' }]
    // console.log(props.index)

    return (_ctx, _cache) => {
      const _component_van_tree_select = _resolveComponent("van-tree-select");
      return __props.index === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tree_select, {
        "main-active-index": data.activeIndex,
        "onUpdate:mainActiveIndex": _cache[0] || (_cache[0] = $event => data.activeIndex = $event),
        height: "88vw",
        items: _unref(items),
        onClickNav: navClick
      }, {
        content: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.subItem, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [_createVNode(ListItemVue, {
            itemContent: item,
            handleAdd: handleAdd,
            handleChange: handleChange
          }, null, 8, ["itemContent"])]);
        }), 128))]),
        _: 1
      }, 8, ["main-active-index", "items"])])) : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.foodData.content), 1));
    };
  }
};